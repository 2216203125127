<template>
    <div class="appointmentCon" >
        <el-dialog
            :visible.sync="appointmentVisiable"       
            width="796px"
            :close-on-click-modal='false'>
            <div class="top_img">
                <img :src="appointmentImg" alt="" srcset="">
                <div class="close" @click="closeDialog">X</div>
                <div class="appointment_title">{{appointmentTitle}}</div>
            </div>
            <div class="tips">
                {{`请填写下方表格，${tipsText}将在48小时内与您取得联系，结合您的需求帮您提供合适的方案`}}
            </div>
            <el-form :model="appointmentForm" :rules="rules" ref="appointmentForm" label-width="130px" class="demo-appointmentForm">
                <el-form-item label="学校名称：" prop="name" v-if="appointmentTitle == '学校预约咨询'">
                    <el-input v-model="appointmentForm.name" placeholder="请输入学校名称"></el-input>
                </el-form-item>
                <el-form-item label="企业名称：" prop="name" v-if="appointmentTitle == '企业内训预约咨询'">
                    <el-input v-model="appointmentForm.name" placeholder="请输入企业名称"></el-input>
                </el-form-item>
                <el-form-item label="企业规模：" v-if="appointmentTitle == '企业内训预约咨询'">
                    <el-select  :popper-append-to-body="false" v-model="appointmentForm.scale" placeholder="请选择企业规模">
                        <el-option v-for="(val,idx) in scaleList" :key="idx" :label="val" :value="val"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所在行业：" prop="major" v-if="appointmentTitle == '学校预约咨询' || appointmentTitle == '企业内训预约咨询'">
                    <el-select  :popper-append-to-body="false" v-model="appointmentForm.major" placeholder="请选择行业">
                        <el-option v-for="(val,idx) in industryList" :key="idx" :label="val" :value="val"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所在城市：" v-if="appointmentTitle == '学校预约咨询' || appointmentTitle == '企业内训预约咨询'">
                    <el-cascader
                        :popper-append-to-body="false"
                        size="large"
                        :options="options"
                        v-model="appointmentForm.city"
                        @change="handleChangeAddress"
                        placeholder="请选择城市"
                    >
                    </el-cascader>
                </el-form-item>
                <el-form-item label="联系人：" prop="contact" v-if="appointmentTitle == '学校预约咨询' || appointmentTitle == '企业内训预约咨询'">
                    <el-input v-model="appointmentForm.contact" placeholder="请输入您的真实姓名"></el-input>
                </el-form-item>
                <el-form-item label="联系电话：" prop="phone" v-if="appointmentTitle == '学校预约咨询' || appointmentTitle == '企业内训预约咨询'">
                    <el-input v-model="appointmentForm.phone" placeholder="请输入您的手机号" ></el-input>
                </el-form-item>
                <el-form-item label="姓名" prop="contact" v-if="appointmentTitle == '学历提升预约咨询' ">
                    <el-input v-model="appointmentForm.contact" placeholder="请输入您的真实姓名"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="phone" v-if="appointmentTitle == '学历提升预约咨询'">
                    <el-input v-model="appointmentForm.phone" placeholder="请输入您的手机号" ></el-input>
                </el-form-item>
                <el-form-item label="学历" prop="education" v-if="appointmentTitle == '学历提升预约咨询'">
                    <el-select :popper-append-to-body="false" v-model="appointmentForm.education" placeholder="请选择学历">
                        <el-option v-for="(val,idx) in educationList" :key="idx" :label="val" :value="val"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="专业" prop="major" v-if="appointmentTitle == '学历提升预约咨询'">
                    <el-select :popper-append-to-body="false"  v-model="appointmentForm.major" placeholder="请选择专业">
                        <el-option v-for="(val,idx) in majorList" :key="idx" :label="val" :value="val"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                   <div class="submit" @click="submitForm('appointmentForm')">提 交</div>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>

</template>

<script>
import {regionData } from "element-china-area-data";
export default {
    name:"appointmentCon",
    props:{
        appointmentVisiable:{
            type:Boolean,
            default: false
        },
        appointmentTitle:String,
        appointmentForm:Object,
    },
    data(){
        return{
            // 学校预约咨询
            appointmentImgList:[
                'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/schoolAppoinment.png',
                // 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/enterpriseAppointment.png',
                // 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/skillAppointment.png'
                require('@/assets/images/school/enterpriseAppointment.png'),
                require('@/assets/images/school/skillAppointment.png'),
            ],
            tipsText:"培考通专业顾问",
            appointmentImg:"",//咨询图片
            // 所在行业列表
            industryList:['电商/互联网业','批发和零售业','教育行业','家具制造业/家居业','房地产业','汽车制造业','保险业','计算机、通信和其他电子设备制造业','交通运输、仓储和邮政业','农、林、牧、渔业','食品制造业','纺织服装、服饰业','医药制造/销售业','其他制造业','住宿和餐饮业','旅游业','信息传输、软件和信息技术服务业','货币金融/资本市场服务业','科学研究和技术服务业','电力、热力生产和供应业','文化、体育和娱乐业','广播、电视、电影和影视录音制作业','其他行业'],
            // 专业列表
            majorList:['护理','家政学','家政服务与管理','养老服务与管理'],
            // 企业规模列表
            scaleList:['低于50人','50-499人','500-999人','1000-9999人','10000-99999人','10万人'],
            // 学历列表
            educationList:['中专','大专','本科'],
            // 三级联动
            options: regionData,
            threeList:[],
            rules:{
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                major:[
                    { required: true, message: '请选择行业/专业', trigger: 'change' },
                ],
                contact:[
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                education:[
                    { required: true, message: '请选择学历', trigger: 'change' },
                ],
                phone:[
                    { required: true,message: '请输入手机号码', trigger: 'blur' },
                    { min: 11, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
                    {
                        pattern: /^(1[3-9][0-9])\d{8}$/,
                        message: '请输入正确的手机号码'
                    }
                ]

            }
        }
    },
    
    watch:{
        appointmentTitle:{
            immediate: true, 
            handler(){
                if(this.appointmentTitle == '学校预约咨询'){
                    this.appointmentImg = this.appointmentImgList[0];
                    this.tipsText = '培考通专业顾问';
                }else if(this.appointmentTitle == '企业内训预约咨询'){
                    this.appointmentImg = this.appointmentImgList[1];
                    this.tipsText = '培考通专业的企业顾问';
                }else{
                    this.appointmentImg = this.appointmentImgList[2];
                    this.tipsText = '培考通专业顾问';
                }
            }
        },
    },
    methods:{
        // 关闭弹窗
        closeDialog(){
            this.$emit('closeDialog')
        },
        // 选择城市
        handleChangeAddress(value) {
            const allArea=require("@/assets/jsons/data.json");
            const provice=allArea["86"];
            if(value){
                var value1 = provice[value[0]];
                var value2 = allArea[value[0]][value[1]];
                var value3 = allArea[value[1]][value[2]];
                this.threeList=[value1,value2,value3];
                // console.log(this.threeList,this.appointmentForm.city,'--------threeList');
            }
        }, 
        // 提交咨询信息
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    this.appointmentForm.city = this.threeList;
                    if(this.threeList && this.threeList.length>0){
                        this.appointmentForm.city = `${this.threeList[0]}${this.threeList[1]=='市辖区'?'':this.threeList[1]}${this.threeList[2]}`;
                        // console.log(this.appointmentForm.city ,'------城市');
                    }else{
                        this.appointmentForm.city = '';
                    }
                    
                    this.$api.submitConsulting(this.appointmentForm).then(res=>{
                        loading.close();
                        if(res.success){
                            this.$message({
                                message: '提交成功~',
                                type: 'success'
                            });
                            this.$emit('closeDialog');
                        }else{
                            this.$message({
                                message: '提交失败,请稍后重试~',
                                type: 'error'
                            });
                        }
                    })
                } else {
                    return false;
                }
            });
        },

    }
}
</script>

<style lang='scss' scoped>
    ::v-deep{
        .el-dialog__headerbtn{
            display: none;
        }
        .el-dialog__header,.el-dialog__body{
            padding: 0;
        }
        .el-dialog__body{
            border-radius: 20px;
        }
        .el-dialog{
            border-radius: 30px;
            position: relative;
        }
        .el-form-item__label {
            text-align: right;
            vertical-align: middle;
            // float: left;
            line-height: 40px;
            // padding: 0 12px 0 0;
            box-sizing: border-box;
            font-size: 18px;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
            font-weight: 400;
            color: #333333;
        }
    }
    .el-form{
        width:500px;
        margin: 0 auto;
        padding-bottom: 20px;
        .el-input,.el-select,.el-cascader{
            width: 400px;
        }
    }
    .top_img{
        width:798px;
        img{
            width:797px;
        }
        .close{
            position:absolute;
            right:10px;
            top:10px;
            width: 42px;
            height: 42px;
            background: rgba(255, 255, 255, .5);
            border-radius: 26px;
            text-align: center;
            line-height: 42px;
            cursor: pointer;
        }
        .appointment_title{
            position: absolute;
            top: 80px;
            left: 0;
            width: 100%;
            font-size: 32px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #FFFFFF;
            text-align: center;
        } 
    }
    .tips{
        margin: 0 auto;
        padding: 20px 0px;
        width: 674px;
        height: 56px;
        font-size: 18px;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        text-indent:36px;
    }
    // 提交按钮
    .submit{
        width: 288px;
        height: 54px;
        line-height: 54px;
        background: #EA5A41;
        border-radius: 27px;
        font-size: 24px;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        cursor: pointer;
    }
</style>